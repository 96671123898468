.header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  min-height: 100px;
  @include mq($until: md) {
    align-items: center;
  }
  &--subsite {
    .header__nav {
      background-color: #ffffff;
    }
    &:after {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 100%;
    width: 50vw;
    background-color: #e9e9e9;
    z-index: -1;
    @include mq($until: md) {
      height: calc(101%);
    }
  }
  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color 0.3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo {
    position: relative;
    z-index: 2;
    max-width: 270px;
    flex-basis: 30%;
    padding-top: 30px;
    padding-bottom: 30px;
    @include mq($until: lg) {
      max-width: 220px;
      flex-basis: 40%;
    }
  }

  &__nav {
    flex-basis: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e9e9;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0;
    padding: 0;
    &--fullscreen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #3e3e3e;
    text-transform: uppercase;
    font-size: rem(24px);
    @include mq($until: xxl) {
      font-size: rem(20px);
    }
    @include mq($until: xl) {
      font-size: rem(18px);
    }
    @include mq($until: lg) {
      font-size: rem(16px);
    }
    @include mq($until: md) {
      font-size: rem(24px);
    }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &--active {
      font-weight: $fw-bold;
    }

    &:active,
    &--open,
    &:hover {
      color: $color-extra;
    }
  }
}
