.subsite {
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq($until: md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &__title {
    margin-bottom: 100px;
    font-weight: 600;
  }
  &__top-img {
    width: 100%;
  }
}
