.features {
  padding-top: 200px;
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include mq($until: md) {
    padding-top: 150px;
  }
  @include mq($until: sm) {
    padding-top: 100px;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: flex-start;
    grid-gap: 150px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    @include mq($until: md) {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    list-style: none;
    margin: 0;
    padding: 0;
    &:before {
      display: none;
    }
  }
  &__link {
    display: grid;
  }
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__icon {
    max-width: 130px;
    margin-right: 50px;
  }
  &__heading p {
    margin: 0;
    text-transform: uppercase;
    font-weight: $fw-extra-bold;
    font-size: rem(26px);
    span {
      font-size: inherit;
      font-weight: $fw-medium;
      text-transform: uppercase;
      line-height: inherit;
    }
  }
  &__text {
    margin: 50px 0 0 0;
    padding: 0;
    font-size: rem(16px);
    font-weight: $fw-semi-bold;
    line-height: rem(28px);
  }
}
