.flat-list {
  @include mq($until: sm) {
    margin-top: 40px;
  }
  @include mq($until: xs) {
    // margin-top: 20px;
  }
  &__wrapper {
    margin-top: 140px;
    margin-bottom: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    @include mq($until: md) {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
  &__head {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: stretch;
    align-items: center;
    padding: 15px 20px;
    background-color: $color-main;
    @include mq($until: md) {
      display: none;
    }
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-main;
      height: 100%;
      z-index: -1;
      width: 100vw;
    }
  }
  &__head-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $fw-medium;
    font-size: rem(20px);
    color: $color-font-light;
    @include mq($until: lg) {
      font-size: rem(16px);
    }
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }
  &__item {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(6, 1fr);
    justify-content: stretch;
    align-items: center;
    transition: 0.3s;
    position: relative;
    background-color: #fff;
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      height: 100%;
      width: 100vw;
      background-color: #fff;
      transition: 0.3s;
      z-index: -1;
    }
    @include mq($until: md) {
      grid-template-columns: 1fr;
      justify-content: flex-start;
      align-items: flex-start;
      grid-gap: 7px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:nth-child(odd) {
      background-color: #e9e9e9;
      &:before {
        background-color: #e9e9e9;
      }
    }
    &:hover {
      background-color: $color-extra;
      &:before {
        background-color: $color-extra;
      }
    }
  }
  &__status {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 5px;
  }
  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
      justify-content: flex-start;
      font-weight: $fw-bold;
      font-size: rem(18px);
    }
  }
  &__item-label {
    display: none;
    @include mq($until: md) {
      display: block;
      margin-right: 10px;
      font-weight: $fw-regular;
      font-size: rem(20px);
    }
  }
  &__item-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
      font-size: rem(18px);
      font-weight: $fw-bold;
    }
    span:first-letter {
      text-transform: uppercase;
    }
    span {
      font-weight: $fw-bold;
      font-size: rem(16px);
      margin-left: 5px;
      @include mq($until: lg) {
        font-size: rem(14px);
      }
      @include mq($until: md) {
        font-size: rem(18px);
      }
    }
  }
  &__no-results {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: rem(26px);
    color: $border-color;
  }
}
