.hero {
  overflow: hidden;
  &__top-slider {
    height: auto;
    height: 40vw;
  }
  &__slide {
    height: 40vw;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &__bottom-bar {
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;
    background-color: #e4e6e5;
    @include mq($until: md) {
      flex-direction: column;
      background-color: transparent;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 100%;
      width: 50vw;
      background-color: $color-main;
      z-index: 0;
      @include mq($until: md) {
        display: none;
      }
    }
  }
  &__heading {
    flex-basis: 70%;
    background-color: $color-main;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0;
    color: $color-font-light;
    font-weight: $fw-extra-bold;
    font-size: rem(30px);
    text-transform: uppercase;
    text-align: left;
    position: relative;
    z-index: 1;
    padding-right: 20px;

    @include mq($until: xl) {
      font-size: rem(26px);
    }
    @include mq($until: lg) {
      font-size: rem(22px);
    }
    @include mq($until: md) {
      font-size: rem(22px);
      padding-right: 0;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100vw;
        background-color: $color-main;
        z-index: -1;
      }
    }
    @include mq($until: sm) {
      font-size: rem(20px);
    }
    @include mq($until: xs) {
      font-size: rem(18px);
    }
    span {
      color: inherit;
      font-size: inherit;
      font-weight: $fw-medium;
      line-height: inherit;
      display: block;
      text-transform: inherit;
      margin-top: 10px;
    }
  }
  &__contact {
    flex-basis: 30%;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4e6e5;
    position: relative;
    z-index: 1;
    font-style: italic;
    font-weight: $fw-extra-bold;
    font-size: rem(26px);
    padding-left: 20px;
    @include mq($until: xxl) {
      font-size: rem(22px);
    }
    @include mq($until: xl) {
      font-size: rem(18px);
    }
    @include mq($until: lg) {
      font-size: rem(16px);
    }
    @include mq($until: md) {
      font-size: rem(24px);
      padding-left: 0;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100vw;
        background-color: #e4e6e5;
        z-index: -1;
      }
    }
    a {
      margin: 0 15px;
      display: inline-block;
      &:hover svg {
        transform: scale(0.95);
      }
      @include mq($until: md) {
        margin: 0 10px;
      }
    }
    svg {
      max-width: 65px;
      transition: transform 0.2s;
      @include mq($until: xl) {
        max-width: 50px;
      }
      @include mq($until: lg) {
        max-width: 45px;
      }
      @include mq($until: md) {
        max-width: 55px;
      }
    }
  }
}
