.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 40vw;
  min-height: 500px;
  position: relative;
  &__heading {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &__header {
    color: $color-font-light;
    font-weight: $fw-extra-bold;
    font-size: rem(50px);
    text-transform: uppercase;
    line-height: rem(50px);
    letter-spacing: 3px;
    @include mq($until: xl) {
      font-size: rem(40px);
      line-height: rem(40px);
    }
    @include mq($until: lg) {
      font-size: rem(30px);
      line-height: rem(30px);
    }
    @include mq($until: sm) {
      font-size: rem(28px);
      line-height: rem(28px);
    }
    span {
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      color: inherit;
      font-weight: $fw-medium;
      letter-spacing: initial;
    }
  }
  &__sub-header {
    color: $color-font-light;
    font-weight: $fw-extra-bold;
    font-size: rem(30px);
    text-transform: uppercase;
    @include mq($until: xl) {
      font-size: rem(25px);
    }
    @include mq($until: sm) {
      font-size: rem(18px);
    }
    @include mq($until: xs) {
      display: grid;
    }
    a {
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
      text-transform: inherit;
      padding: 10px 25px;
      background-color: $color-extra;
      margin-right: 15px;
      transition: background-color 0.2s;
      @include mq($until: xs) {
        order: 2;
        text-align: center;
        margin-right: 0;
        margin-top: 15px;
      }
      &:hover {
        background-color: $color-main;
      }
    }
  }
}
