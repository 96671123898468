.widget {
  overflow: hidden;
  &__top-header {
    font-size: rem(40px);
    font-weight: $fw-bold;
    margin: 0;
    text-align: center;
    padding-bottom: 50px;
    padding-top: 100px;
    @include mq($until: md) {
      padding-top: 50px;
    }
    &--home {
      padding-top: 200px;
      @include mq($until: md) {
        padding-top: 150px;
      }
      @include mq($until: sm) {
        padding-top: 100px;
      }
    }
    &--modal {
      padding-top: 0;
      padding-bottom: 0;
      @include mq($until: lg) {
        font-size: rem(30px);
      }
      @include mq($until: md) {
        padding-top: 30px;
      }
    }
  }
  &__tooltip {
    padding: 5px 15px;
    color: $color-font-light;
    box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.3);
    position: absolute;
    height: auto;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    transform: scale(0);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &__tooltip-text {
    color: inherit;
    text-align: center;
    font-size: rem(14px);
    white-space: nowrap;
  }
  &__tooltip-counter {
    font-size: inherit;
    color: inherit;
  }

  &__modal {
    padding-top: 20px;
    padding-bottom: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 2;
    transform: translateX(100%);
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: 1.2s;
    &--step2 {
      z-index: 3;
      opacity: 1;
    }
    &.is-open {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__flat-status {
    &--green {
      fill: #87d779;
      cursor: pointer;
      opacity: 0.8;
      transition: fill 0.3s;
      &:hover {
        fill: #cacaca;
      }
    }
    &--yellow {
      fill: #f7d371;
      cursor: pointer;
      cursor: context-menu;
      opacity: 0.8;
    }
    &--red {
      fill: #f17a76;
      cursor: context-menu;
      opacity: 0.8;
    }
  }

  &__modal-legend {
    position: absolute;
    right: 5vw;
    top: 5vw;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    padding: 35px;
    @include mq($until: lg) {
      padding: 10px 20px;
      position: static;
      width: 100%;
      top: auto;
      left: auto;
      order: 2;
    }
  }
  &__legend-header {
    color: $color-font-dark;
    font-size: rem(22px);
    margin-bottom: 22px;
    @include mq($until: xs) {
      margin-bottom: 10px;
    }
  }
  &__legend-item {
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    align-items: center;
  }
  &__legend-color {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    @include mq($until: xs) {
      width: 15px;
      height: 15px;
    }
    &--green {
      background-color: #87d779;
      border: 1px solid #66c542;
    }
    &--yellow {
      background-color: #f7d371;
      border: 1px solid #dabf4c;
    }
    &--red {
      background-color: #f17a76;
      border: 1px solid #c75d55;
    }
  }
  &__legend-text {
    color: $color-font-dark;
  }
  &__modal-head {
    display: grid;
    grid-template-areas: "left center .";
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-self: flex-start;
    margin-bottom: 30px;
    @include mq($until: md) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "left"
        "center";
    }
  }
  &__modal-head-left {
    grid-area: left;
    h2 {
      white-space: nowrap;
    }
  }
  &__modal-head-right {
    grid-area: center;
  }
  &__modal-head-building {
    font-size: inherit;
    font-weight: inherit;
  }
  &__modal-back {
    border: none;
    outline: none;
    background-color: transparent;
    transition: color 0.2s;
    i {
      margin-right: 8px;
      transition: inherit;
    }
    &:hover {
      color: $color-extra;
      i {
        color: $color-extra;
      }
    }
  }
  &__svg {
    display: block;
  }
  &__form {
    display: flex;
    justify-content: center;
    align-items: stretch;
    background-color: #e9e9e9;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 100%;
      width: 50vw;
      background-color: #ffffff;
      z-index: 0;
    }
    @include mq($until: md) {
      flex-direction: column;
      background-color: transparent;
    }
  }
  &__inner-wrapper {
    display: grid;
    grid-gap: 40px;
    justify-content: stretch;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-basis: 80%;
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    padding-right: 40px;
    @include mq($until: md) {
      padding-right: 0;
    }
  }
  &__header {
    align-self: flex-start;
    margin: 0;
    color: $color-main;
    font-weight: $fw-extra-bold;
    font-size: rem(30px);
    text-transform: uppercase;
    span {
      color: inherit;
      font-size: inherit;
      font-weight: $fw-medium;
      line-height: inherit;
      text-transform: inherit;
    }
  }
  &__fields-wrapper {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    align-items: center;
    @include mq($until: md) {
      // grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: sm) {
      grid-template-columns: 1fr;
    }
  }
  &__button-wrapper {
    flex-basis: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background-color: #e9e9e9;
    padding: 0;
    padding-left: 40px;
    @include mq($until: md) {
      padding-left: 0;
      position: relative;
      &:before {
        content: "";
        background-color: #e9e9e9;
        height: 100%;
        width: 100vh;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
      }
    }
    @include mq($until: xl) {
      flex-direction: column;
    }
    @include mq($until: md) {
      flex-direction: row;
    }
    @include mq($until: xs) {
      flex-direction: column;
      padding-bottom: 30px;
    }
    span {
      margin: 0 20px;
      font-size: rem(16px);
      font-weight: $fw-extra-bold;
      @include mq($until: xl) {
        margin: 0 0 15px 0;
      }
      @include mq($until: md) {
        margin: 0 15px;
      }
      @include mq($until: xs) {
        margin: 0 0 15px 0;
      }
    }
  }
  &__button {
    flex-basis: 20%;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $fw-extra-bold;
    font-style: italic;
    font-size: rem(26px);
    color: $color-main;
    transition: color 0.2s;
    padding: 0;
    @include mq($until: lg) {
      font-size: rem(22px);
    }
    @include mq($until: md) {
      font-size: rem(20px);
    }
    &:hover {
      color: $color-extra;
      svg {
        transform: scale(0.95);
      }
    }
    svg {
      margin-left: 15px;
      max-width: 65px;
      transition: transform 0.2s;
      @include mq($until: lg) {
        max-width: 55px;
      }
      @include mq($until: md) {
        max-width: 50px;
      }
    }
  }
  &__see-all {
    white-space: nowrap;
    font-size: rem(16px);
    position: relative;
    font-weight: $fw-extra-bold;
    &:hover::after {
      background-color: $color-main;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 100%;
      transition: 0.3s;
      background-color: $color-extra;
    }
  }
  &__input-wrapper {
    border: 2px solid $color-main;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // min-width: 250px;
    max-width: 300px;
    @include mq($until: sm) {
      max-width: none;
    }
  }
  &__label {
    position: absolute;
    bottom: 0;
    left: -2px;
    transform: translateY(calc(50% - 7px));
    background-color: #ffffff;
    color: $color-extra;
    font-size: rem(16px);
    font-weight: $fw-semi-bold;
    line-height: rem(16px);
    padding: 10px 10px 0 0;
    transition: color 0.2s;
    @include mq($until: xxl) {
      bottom: -2px;
    }
  }
  &__input {
    padding: 15px 50px;
    border: none;
    width: 100%;
    color: $color-main;
    font-size: rem(22px);
    line-height: rem(32px);
    font-weight: $fw-extra-bold;
    text-align: center;
    -webkit-appearance: none;
    background-color: transparent;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    cursor: pointer;
    background-image: url("../../images/arrow-down.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: calc(100% - 10px) center;
    @include mq($until: xxl) {
      font-size: rem(16px);
      line-height: rem(26px);
      padding: 15px 20px;
    }
    &:hover + .widget__label {
      color: $color-main;
    }
  }
  &__building {
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    &--disable {
      cursor: context-menu;
      opacity: 0.5;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__svg-wrapper {
    position: relative;
  }

  &__text-box {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 3%,
      rgba(255, 255, 255, 0.8007935596113446) 27%
    );
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 20px;
    position: absolute;
    transform-origin: bottom center;
    transform: translate(-50%, -50%);
    &::before {
      content: "";
      position: absolute;
      top: calc(100% - 0.5px);
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #ffffff;
      @include mq($until: md) {
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #ffffff;
      }
      @include mq($until: xs) {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #ffffff;
      }
    }
  }
  &__building-symbol {
    font-size: rem(40px);
    font-weight: $fw-extra-bold;
    @include mq($until: md) {
      font-size: rem(30px);
    }
    @include mq($until: sm) {
      font-size: rem(20px);
    }
    @include mq($until: xs) {
      font-size: rem(16px);
    }
  }
  &__text-box-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding-left: 15px;
    @include mq($until: xs) {
      padding-left: 5px;
    }
  }
  &__level {
    white-space: nowrap;
    text-align: left;
    font-size: rem(14px);
    font-weight: $fw-extra-bold;
    @include mq($until: sm) {
      font-size: rem(12px);
    }
    @include mq($until: xs) {
      font-size: rem(10px);
    }
  }
  &__date {
    white-space: nowrap;
    font-size: rem(14px);
    text-align: left;
    font-weight: $fw-extra-bold;
    @include mq($until: sm) {
      font-size: rem(12px);
    }
    @include mq($until: xs) {
      font-size: rem(10px);
    }
  }
}

.step1 {
  &__building {
    width: 100%;
    max-height: calc(100vh - 200px);
    display: none;
    & > svg {
      position: absolute;
      max-height: calc(100vh - 200px);
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      max-width: calc(100% - 100px);
    }
    @include mq($until: xxl) {
      // max-height: 900px;
    }
    &.active {
      display: block;
    }
  }
  &__floor {
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}

.step2 {
  &__floor {
    max-width: 2000px;
    z-index: 2;
    display: none;
    transition: opacity 0.3s;
    opacity: 0;
    overflow: hidden;
    position: relative;

    &.active {
      display: block;
      overflow: visible;
      opacity: 1;
    }

    &--static {
      display: block;
      opacity: 1;
      path,
      rect {
        fill: transparent;
        &:hover {
          fill: transparent;
        }
      }
    }
  }
}
