.footer {
  position: relative;
  &__bg {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    @include mq($until: sm) {
      justify-content: center;
      align-items: flex-start;
    }
  }
  &__left-col-bg {
    background-color: #ffffff;
    width: 40%;
    @include mq($until: xl) {
      width: 30%;
    }
    @include mq($until: sm) {
      width: 100%;
      height: 120px;
    }
  }
  &__img-wrapper {
    background-repeat: no-repeat;
    background-image: url("../../images/bg-foto.jpg");
    background-size: cover;
    background-position: center;
    width: 60%;
    @include mq($until: xl) {
      width: 70%;
    }
    @include mq($until: sm) {
      width: 100%;
      height: calc(100% - 120px);
    }
  }

  &__top-bar {
    display: grid;
    position: relative;
    grid-template-columns: 3fr 5fr;
    padding-top: 40px;
    padding-bottom: 40px;
    @include mq($until: xl) {
      grid-template-columns: 2fr 5fr;
    }
    @include mq($until: sm) {
      grid-template-columns: 1fr;
      grid-template-rows: 120px 1fr;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__logo-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    @include mq($until: sm) {
      justify-content: center;
    }
  }
  &__logo {
    display: block;
    @include mq($until: sm) {
      max-width: 220px;
    }
  }
  &__nav-wrapper {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  &__menu-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include mq($until: sm) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include mq($until: xs) {
      flex-wrap: wrap;
    }
  }
  &__item {
    padding: 0;
    margin: 0 10px 0 10px;
    @include mq($until: xs) {
      margin: 10px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:before {
      display: none;
    }
  }
  &__link {
    color: #ffffff;
    text-transform: uppercase;
    font-size: rem(24px);
    @include mq($until: xxl) {
      font-size: rem(20px);
    }
    @include mq($until: xl) {
      font-size: rem(18px);
    }
    @include mq($until: lg) {
      font-size: rem(16px);
    }
    @include mq($until: md) {
      font-size: rem(14px);
    }

    &--active {
      font-weight: $fw-bold;
    }

    &:active,
    &--open,
    &:hover {
      color: $color-extra;
    }
  }

  &__bottom-section {
    display: grid;
    position: relative;
    grid-template-columns: 5fr 4fr;
    @include mq($until: sm) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
    }
  }

  &__map {
    position: relative;
    @include mq($until: sm) {
      order: 2;
    }
    .map {
      z-index: 1;
      height: 100%;
      @include mq($until: lg) {
        // height: 100%;
      }
      @include mq($until: sm) {
        height: 300px;
      }
    }
  }
  &__image {
    position: relative;
    background-image: url("../../images/foto-za-mape.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include mq($until: sm) {
      display: none;
    }
  }
  &__contact {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: sm) {
      padding-bottom: 40px;
      justify-content: flex-start;
      order: 1;
    }
  }
  &__contact-inner-wrapper {
    display: grid;
    grid-gap: 40px;
  }
  &__contact-logo {
    max-width: 500px;
    @include mq($until: sm) {
      max-width: 250px;
    }
    img {
      display: block;
    }
  }
  &__contact-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &:last-child {
      margin-bottom: 25px;
      @include mq($until: sm) {
        margin-bottom: 0;
      }
    }
  }
  &__contact-icon {
    margin-right: 45px;
    @include mq($until: xl) {
      margin-right: 30px;
    }
    @include mq($until: lg) {
      margin-right: 20px;
    }
    svg {
      fill: $color-extra;
    }
  }

  &__contact-content {
    p,
    span {
      color: $color-font-light;
      margin: 0;
    }
    &--emails,
    &--phones {
      display: flex;
      flex-wrap: wrap;
      a {
        color: $color-font-light;
        &:hover {
          color: $color-extra;
        }
      }
    }
    &--emails {
      flex-direction: column;
    }
    &--phones {
      flex-direction: row;
      a {
        margin: 5px 5px 0 5px;
        position: relative;
        padding-right: 15px;
        white-space: nowrap;
        &:after {
          position: absolute;
          content: "|";
          color: $color-extra;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
          padding-right: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.footer-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  flex-wrap: wrap;
  @include mq($until: xs) {
    flex-direction: column;
  }
  &__rodo {
  }
  &__copyright {
    @include mq($until: xs) {
      margin-top: 10px;
    }
  }
  &__realization {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mq($until: md) {
      flex-basis: 100%;
      justify-content: center;
      margin-top: 15px;
    }
    span {
      transition: color 0.3s ease-in-out;
    }
    &:hover span {
      color: $color-main;
    }
    img {
      margin-left: 15px;
      max-width: 100px;
    }
  }
}
