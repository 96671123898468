.flat-info {
  display: grid;
  grid-template-columns: 3fr 4fr;
  justify-content: center;
  align-items: stretch;
  @include mq($until: md) {
    grid-template-columns: 1fr;
  }
  &__info-wrapper {
    padding-top: 50px;
  }
  &__flat-title {
    background-color: $color-main;
    color: $color-font-light;
    text-transform: uppercase;
    font-size: rem(30px);
    text-align: left;
    padding-top: 35px;
    padding-bottom: 35px;
    margin: 0;
    font-weight: $fw-medium;
    position: relative;
    @include mq($until: xl) {
      font-size: rem(26px);
    }
    span {
      font-weight: $fw-bold;
      font-size: inherit;
      color: inherit;
      line-height: inherit;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      height: 100%;
      width: 50px;
      background-color: $color-main;
    }
  }
  &__details {
    padding-top: 60px;
    padding-bottom: 60px;
    display: grid;
    grid-gap: 20px;
  }
  &__details-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__details-label {
    text-transform: uppercase;
    font-size: rem(18px);
    @include mq($until: xl) {
      font-size: rem(16px);
    }
  }
  &__details-value {
    text-transform: uppercase;
    font-weight: $fw-extra-bold;
    font-size: rem(20px);
    margin-left: 10px;
    white-space: nowrap;
    @include mq($until: xl) {
      font-size: rem(18px);
    }
  }
  &__summary {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 40px;
    padding-top: 30px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #e9e9e9;
    }
  }
  &__summary-label {
    text-transform: uppercase;
    font-size: rem(18px);
    @include mq($until: xl) {
      font-size: rem(16px);
    }
  }
  &__summary-value {
    text-transform: uppercase;
    font-weight: $fw-extra-bold;
    margin-top: 10px;
    font-size: rem(20px);
    @include mq($until: xl) {
      font-size: rem(18px);
    }
  }
  &__preview-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e9e9;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &__preview-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__preview {
    margin-bottom: 30px;
  }
  &__buttons {
    display: flex;
    justify-content: stretch;
    align-items: center;
    @include mq($until: xl) {
      flex-direction: column;
    }
  }
  &__contact {
    margin-right: 10px;
    background-color: #fff;
    padding: 15px 30px;
    border: none;
    color: $color-font-dark;
    text-transform: lowercase;
    font-style: italic;
    font-weight: $fw-bold;
    font-size: rem(18px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.2s;
    @include mq($until: xl) {
      margin-right: 0;
      margin-bottom: 30px;
      font-size: rem(16px);
    }

    svg {
      position: relative;
      margin: 0 15px;
      width: 60px;
      height: 30px;
      transform: scale(2.3);
    }
    &:hover {
      color: $color-extra;
    }
  }
  &__get-file {
    margin-left: 10px;
    background-color: #fff;
    padding: 15px 0px 15px 30px;
    color: $color-font-dark;
    font-style: italic;
    text-transform: lowercase;
    font-weight: $fw-bold;
    font-size: rem(18px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: color 0.2s;
    @include mq($until: xl) {
      margin-left: 0;
      font-size: rem(16px);
    }
    svg {
      position: relative;
      left: 30px;
      margin: 0 15px;
      width: 60px;
      height: 30px;
      transform: scale(2.3);
    }
    &:hover {
      color: $color-extra;
    }
  }
}

.flat-location {
  display: grid;
  grid-template-columns: 3fr 4fr;
  justify-content: center;
  align-items: stretch;
  @include mq($until: md) {
    grid-template-columns: 1fr;
  }
  &__building {
    background-color: $color-main;
    padding-top: 60px;
    padding-bottom: 60px;
    // padding-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__heading {
    text-align: left;
    width: 100%;
    color: $color-font-light;
    font-size: rem(30px);
    font-weight: $fw-medium;
    text-transform: uppercase;
    margin-bottom: 30px;
    @include mq($until: xl) {
      font-size: rem(26px);
    }
    span {
      color: inherit;
      font-size: inherit;
      font-weight: $fw-extra-bold;
      line-height: inherit;
      text-transform: uppercase;
    }
  }
  &__floor {
    padding-top: 60px;
    padding-bottom: 60px;
    // padding-left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__building-path {
    fill: #c1c1c1;
    &.active {
      fill: $color-extra;
      stroke: black;
      stroke-width: 0.3px;
      stroke-linecap: butt;
      stroke-miterlimit: 2;
    }
  }
}

.flat-features {
  padding-top: 12vw;
  padding-bottom: 12vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @include mq($until: xl) {
    justify-content: center;
    align-items: center;
    // display: grid;
    // grid-template-columns: repeat(2, 350px);
    // justify-content: center;
    // align-items: center;
    // grid-gap: 80px;
  }
  &__item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 30px;
  }
  &__icon {
    width: 155px;
  }
  &__heading {
    text-transform: uppercase;
    font-size: rem(26px);
    text-align: center;
    font-weight: $fw-extra-bold;
    color: $color-font-dark;
    @include mq($until: xl) {
      font-size: rem(24px);
    }
    span {
      display: block;
      font-weight: $fw-medium;
      font-size: rem(24px);
      line-height: inherit;
      color: inherit;
      text-align: center;
      @include mq($until: xl) {
        font-size: rem(20px);
      }
    }
  }
}
